







































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'ProjectContent',
  props: {
    content: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const isOpen = ref(props.open)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value

      if (isOpen.value) {
        open()
        ctx.emit('open')
      } else {
        close()
      }
    }

    const open = () => {
      const tl = gsap.timeline()

      tl.to(inner.value, {
        duration: 0.5,
        height: 'auto',
        ease: 'power4',
      })
    }

    const close = () => {
      const tl = gsap.timeline()

      tl.to(inner.value, {
        duration: 1,
        height: '55rem',
        ease: 'power4.inOut',
      })
    }

    return {
      inner,
      isOpen,
      toggle,
      vBar,
    }
  },
})
